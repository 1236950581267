.c-header {
  position: relative;
  z-index: 100;
  background: var(--theme-header-background-color);
}
.c-header__inner {
  width: 100%;
  height: 64px;
  padding: 0 32px;
  /* background: #fff; */
  box-shadow: 0 2px 4px rgba(60, 64, 67, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.c-header__ttl {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-header__ttl__logo {
  /* min-width: 32px;
  height: 32px; */
  border-right: 1px solid #ddd;
  padding-right: 16px;
  margin-right: 16px;
}
.c-header__ttl__logo img {
  /* width: auto;
  height: inherit; */
  min-width: 32px;
  max-width: 96px;
  height: 32px;
}
.c-header__ttl__name {
  font-size: 20px;
  font-weight: bold;
}
.c-header__lang.base-select {
    width: 160px;
  }
.c-header__lang {
  width: 128px;
}
.c-header__lang__ic {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.c-header__nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-header__nav__list {
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-header__nav__list__item {
}
.c-header__nav__list__item__childrenMenu {
  position: relative;
}
.c-header__nav__list__item > a,
.c-header__nav__list__item__childrenMenu > p {
  font-weight: bold;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.c-header__nav__list__item__childrenMenu > p::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin-left: 8px;
}
.c-header__nav__list__item__childrenMenu > ul {
  display: none;
  width: 320px;
  /* max-height: 400px; */
  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
  background: #fff;
  background: var(--theme-header-background-color);
  position: absolute;
  top: 21px;
  left: calc(-50% - 64px);
  overflow-y: auto;
}
.c-header__nav__list__item__childrenMenu:hover ul {
  display: block;
}
.c-header__nav__list__item__childrenMenu li {
}
.c-header__nav__list__item__childrenMenu a {
  width: 100%;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.c-header__menu {
  display: none;
}
.c-header__nav__list__item > a:hover,
.c-header__nav__list__item__childrenMenu > p:hover,
.c-header__nav__list__item__childrenMenu a:hover {
  opacity: 0.8;
}
.c-header__fixed {
  overflow: hidden;
}
@media screen and (max-width: 820px) {
  .c-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .c-header__h1 {
    font-size: 10px;
    background: #fff;
    padding: 4px 8px;
    position: static;
  }
  .c-header__inner {
    height: 56px;
    padding: 0 0 0 8px;
  }
  .c-header__ttl {
    width: auto;
    height: 32px;
    margin-top: 0;
  }
  .c-header__flg {
    display: none;
  }
  .c-header__menu {
    width: 56px;
    height: 56px;
    position: relative;
    display: block;
  }
  .c-header__menu::before,
  .c-header__menu::after,
  .c-header__menu > span {
    width: 24px;
    height: 2px;
    background: #333;
    position: absolute;
    left: 16px;
  }
  .c-header__menu::before {
    content: '';
    top: 19px;
    transition: all 0.4s ease;
  }
  .c-header__menu > span {
    top: 27px;
  }
  .c-header__menu::after {
    content: '';
    top: 36px;
    transition: all 0.4s ease;
  }
  .c-header__flg:checked + .c-header__menu::before {
    top: 27px;
    transform: rotate(45deg);
  }
  .c-header__flg:checked + .c-header__menu > span {
    opacity: 0;
  }
  .c-header__flg:checked + .c-header__menu::after {
    top: 27px;
    transform: rotate(-45deg);
  }
  .c-header__flg:checked ~ .c-header__nav {
    max-height: calc(100vh - 56px);
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-header__nav {
    width: 100%;
    background: #f9f9f9;
    position: absolute;
    top: 56px;
    left: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease;
    display: block;
  }
  .c-header__nav__list {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
  .c-header__nav__list__item {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
  }
  .c-header__nav__list__item > a {
    border-top: 1px solid #ddd;
  }
  .c-header__nav__list__item a {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    background: #fff;
    position: relative;
  }
  .c-header__nav__list__item a::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ddd;
    border-right: 2px solid #ddd;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 7px);
    right: 8px;
  }
  .c-header__nav__list__item__childrenMenu {
  }
  .c-header__nav__list__item__childrenMenu p {
    line-height: 32px;
    padding: 0 16px;
  }
  .c-header__nav__list__item__childrenMenu p::after {
    display: none;
  }
  .c-header__nav__list__item__childrenMenu p:hover {
    opacity: 1;
  }
  .c-header__nav__list__item__childrenMenu > ul {
    display: block;
    width: 100%;
    max-height: auto;
    border-width: 0;
    box-shadow: none;
    position: static;
    padding: 0;
  }
  .c-header__nav__list__item__childrenMenu li {
    border-top: 1px solid #ddd;
  }
  .c-header__lang {
    margin: 32px auto 160px auto;
  }
  .c-header__ttl__name {
    font-size: 16px;
    line-height: 18px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
